<template>
  <div class="mainContainer container" style="">
    <div style="display:flex;">
      <div id="logoDiv"><img src="../assets/logo_w_text_250.png"></div>

    </div>
    <h5 class="pageTitle">Upgrade Required</h5>
    <hr>
    <div class="msg">
      A new version is available. An upgrade is required to continue to use the service.
      <div class="appstores">
        <div v-if="mobileOS==='iOS' || mobileOS==='Other'" @click="gotoAppStore"><img src="../assets/appstores/AppleAppStore.png"> </div>
        <div v-if="mobileOS==='Android' || mobileOS==='Other'" @click="gotoGooglePlay"><img src="../assets/appstores/GooglePlay.png"> </div>
        <div style="margin-top:20px;">or use the web app</div>
        <div @click="gotoWebApp"><img src="../assets/appstores/WebApp2.png"> </div>
      </div>

<!--      <div class="logout btn btn-danger" @click.prevent="logOut"><div class="actionIcon"><font-awesome-icon :icon="['fas', 'power-off']" /></div><div>Log Out</div></div>-->
    </div>
  </div>
</template>

<script>



export default {
  name: 'Outdated',
  components: {

  },
  data(){
    return{
      mobileOS:null,
    }
  },
  methods:{
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$root.gotoRoute('/login');
    },

    getMobileOS() {
      const ua = navigator.userAgent
      if (/android/i.test(ua)) {
        return "Android"
      }
      else if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)){
        return "iOS"
      }
      return "Other"
    },

    gotoAppStore(){
      window.open('https://apps.apple.com/app/id1617461351');
    },
    gotoGooglePlay(){
      window.open('https://play.google.com/store/apps/details?id=com.goblinbank.app');
    },
    gotoWebApp(){
      window.open('https://app.goblinbank.com');
    },

  },
  mounted() {
    document.title = "App is outdated";
    this.mobileOS = this.getMobileOS();
    this.$store.dispatch('auth/logout');


  }
}
</script>

<style scoped>
 .msg{
   margin:50px 10px 10px 10px;
   padding:10px;
   text-align: center;
 }

 #logoDiv{
   margin:0 auto;
   padding:10px 20px;
   text-align: center;
 }

 #logoDiv img{
   width:200px;
 }

.pageTitle{
  text-align: center;
  margin-top:20px;
}

.logout{
  display:flex;
  justify-content: center;
  align-items: center;
  width:200px;
  margin:30px auto;
}

.actionIcon{
  margin-right:10px;
}

.appstores{
  margin-top:20px;
}

.appstores div{
  text-align: center;
}

.appstores img{
  margin:10px auto;
  width:200px;
}

</style>
